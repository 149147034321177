.product {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product__image {
  width: 250px;
  height: 250px;
  border-radius: 1rem;
  object-fit: contain;
  margin-right: 20px;
  margin-bottom: 20px;
}

.product__info {
  display: flex;
  flex:2;
  flex-direction: column;
  flex-grow: 1;
}


.product__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product__description {
  font-size: 18px;
  margin-bottom: 10px;
}

.product__price {
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.center {
    display: flex;
    justify-content: flex-end;
    flex:1;
    position: relative;
}

@media (max-width: 1280px) {
  .product {
    flex-direction: column;
  }

  .product__info {
    justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  }

  .app__menuitem-price {
    display: flex;
    justify-content: center;
    align-items: center;
}

  .center {
    display: block;
    justify-content: center;
    flex:1;
    position: relative;
}

  .product__image {
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .product__price {
    margin-left: 0;
    margin-top: 20px;
  }
}
.language-switch {
    position: fixed;
    top: 5%;
    right: 20px;
    transform: translateY(-50%);
    border-radius: 50px;
}

.language-button {
    padding: 8px;
    margin-right: 5px;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    cursor: pointer;
    z-index: 1000;
}

.language-button.active {
    color: #333;
    background-color: #fff;
}